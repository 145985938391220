<template>
    <div class="intro-box">
        <div class="yingjingdaohang" @click="goDefaultPage">福五金</div>
        <div class="zhichangdaohang" @click="goCareerPage">不知礼</div>
        <div class="aigcfocus" @click="goMediaHelperPage">媒辅助</div>
    </div>
    <!-- <view class="container"> -->
</template>

<script>

export default {
  name: 'Intro',
  created(){
    document.title = '案例展示'
  },
  data(){
      return {
      }
  },
  methods:{
    goDefaultPage(){
        window.location.href = 'https://yingjing.emer-nav.cn'
    },
    goCareerPage(){
        window.location.href = 'https://career.emer-nav.cn'
    },
    goMediaHelperPage(){
        window.location.href = 'https://emer-nav.cn'
    },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.intro-box {
    height: 100vh;
    overflow: hidden;
    display: flex;
    box-sizing: content-box;
    padding: 10% 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
    .title {
        font-size: 20px;
        margin: 10px;
        color:coral;
    }

    .yingjingdaohang {
        border: 1px coral solid;
        background: #f3f3f3;
        width: 50%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin: 10px 0;
        font-size: 22px;
        color: #2F2F4F;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    .zhichangdaohang {
        background: #f3f3f3;
        width: 50%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin: 10px 0;
        color: #2F2F4F;
        font-size: 22px;
        border: 1px coral solid;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    .aigcfocus {
        background: #f3f3f3;
        width: 50%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin: 10px 0;
        color: #2F2F4F;
        font-size: 22px;
        border: 1px coral solid;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }
}
</style>
